@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

:root {
  --primary_color: #15817c;
}

body {
}

.va_modal_header {
  font-size: 16px;
}

.va_modal_ul li {
  font-size: 14px;
}

.list-header {
  font-size: 16px;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .va_modal_header {
    font-size: 12px;
  }

  .va_modal_ul li {
    font-size: 12px;
  }

  .list-header {
    font-size: 12px;
    font-weight: bold;
  }
}

main {
  min-height: 80vh;
}

.row {
  margin: 0;
}

.nav-links {
  text-transform: uppercase;
  color: #178b86 !important;
  font-size: 13px;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 990px) {
  .dropdown-menu {
    border: none;
    background: none;
    text-align: center;
  }
}

.navbar-nav .nav-link {
  padding: 5px;
}

.logo-mobile {
  display: none;
}

.logo-desktop {
  display: block;
}

.dropdown {
  font-size: 13px;

  color: #15817c;
}

.navbar-light .navbar-nav .nav-link {
  color: #15817c !important;
}

.dropdown-item {
  color: #15817c;
  font-size: 13px;
}

.hero_container {
  background: radial-gradient(
    circle,
    rgba(238, 238, 238, 1) 0%,
    rgba(56, 129, 124, 0.3) 100%
  );
}

.hero-body {
  background-image: url("./assets/hero-img3.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 50vh;
  border: 1px solid #333;
}

.hero-text {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  /* padding-top: 120px; */
}

.hero-text h1 {
  font-size: 50px;
}

.hero-text h4 {
  font-size: 30px;
}

.hero-text-header-p {
  font-size: 14px;
  margin: 0;
}

.hero-text-p {
  font-size: 12px;
}

.hero-button {
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 25px;
  letter-spacing: 0.5px;
  font-weight: bold;
  border: 3px solid #fff;
}

.button-container {
  padding-top: 20px;
}

.button-container p {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

.hero-button:hover {
  color: #005551;
  background: #fff;
}

/*Card Section 2*/
.card-body {
  padding-top: 10px;
}
.card_header_section2 {
  padding: 40px 0;
}

.card_left_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_icon {
  border-radius: 50%;
  padding: 10px;
  color: white;
  margin-right: 5px;
}

.eye {
  background: #f7d307;
}

.task {
  background: #8f47b3;
}

.wallet {
  background: #50bfe6;
}

.chart {
  background: #00cc99;
}

.card_section2 {
  border: 1px solid #178b86;
}

.card_section2 li {
  list-style: none;
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

.va_box1 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box2 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box3 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box4 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service4.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box5 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service5.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box6 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service6.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box7 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service7.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.va_box8 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.8)),
    url("./assets/services/service8.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/*Card Section 3*/
.card_container3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card_container3 i {
  font-size: 30px;
  margin-bottom: 10px;
}

.card_container3 h2 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #178b86;
  text-align: center;
}

.card_container3 li {
  font-size: 16px;
}

.building {
  color: #f7d307;
}
.buildings {
  color: #8f47b3;
}
.medical {
  color: #50bfe6;
}
.list {
  color: #00cc99;
}

/*Card Section 3 End*/

.card-footer {
  border: none !important;
}

.card-footer {
  padding: 0px 20px 20px 20px;
}

.modal_button {
  margin-top: 0px !important;
  border: 1px solid #fff;
  margin: 2px;
}

.card_header_section2 h1 {
  font-size: 30px;
  text-transform: uppercase;
  color: var(--primary_color);
  border-left: 5px solid #00c573;
  padding-left: 15px;
  font-weight: bold;
  letter-spacing: 1px;
}

.card_header_section2 p {
  line-height: 2rem;
  font-size: 14px;
}

.card_section_icons h4 {
  font-size: 16px;
  font-weight: bold;
  color: #178b86;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.card-header {
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #fff;
}
.list-group-item {
  color: #333;
}

@media only screen and (max-width: 600px) {
  .card_header_section2 {
    padding: 20px;
  }
  .card_header_section2 h1 {
    font-size: 18px;
  }
  .card_header_section2 p {
    font-size: 14px;
  }
  .card_icon {
    font-size: 14px;
  }
  .card_section_icons p {
    font-size: 14px;
  }
  .card-header {
    font-size: 14px;
  }
  .list-group-item {
    font-size: 12px;
  }
  .card_section_icons h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .card_header_section2 {
    padding: 20px 0px;
  }
}

/*Review Carousel Section*/

.carousel-control-prev-icon {
  background-image: none;
}
.carousel-control-next-icon {
  background-image: none;
}
.carousel-indicators .active {
  opacity: 0;
}

.carousel-section {
  /* border: 1px solid #005551; */
  margin: 50px 0;
}

.carousel_img {
  border: 5px solid #005551;
}

.carousel_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #005551;
}

.carousel_text h3 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.carousel_text h4 {
  font-size: 17px;
}

.carousel_text p {
  font-size: 15px;
  color: #178b86;
  font-style: italic;
}

.carousel_icons {
  color: #f8e825;
  padding: 5px;
  font-size: 20px;
}

@media only screen and (max-width: 765px) {
  .carousel-section {
    margin-top: 35px;
    margin-bottom: 0px;
  }
  .carousel_text h3 {
    padding-top: 20px;
    font-size: 18px;
  }
  .carousel_text h4 {
    font-size: 16px;
  }
  .carousel_text p {
    font-size: 14px;
  }
}

/*Card section*/

.card-header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.card-header-section-image {
  padding-top: 50px;
}

.card-header-section-text h3 {
  color: #005551;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 25px;
  border-left: 5px solid #00c573;
  padding-left: 15px;
}
.card-header-section-text p {
  color: #005551;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  padding-left: 20px;
}

.card-header-section-text h4 {
  color: #005551;
  font-size: 16px;
}

.card-header-icons {
  display: flex;
  padding-bottom: 15px;
}

.card-header-icons-h4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icons {
  color: #00c573 !important;
  padding: 15px;
  margin-right: 20px;
  font-size: 30px;
}

.card-section {
  /* background: radial-gradient(
    circle,
    rgba(238, 238, 238, 1) 0%,
    rgba(56, 129, 124, 0.3) 100%
  ); */
}

.card-section-image {
  text-align: center;
}

.list-group-flush {
  text-align: start;
}

.list-group-item {
  padding: 10px 0px;
  font-size: 14px;
}

.card-section p {
  color: #178b86;
  font-size: 16px;
}

.card-section h1 {
  color: #005551;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 24px;
}

.card-section h4 {
  color: #178b86;
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
}

.card-section-h3 {
  color: #005551 !important;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 30px;
  text-align: center;
}

.cards2 {
  border: 1px solid #178b86;
  height: 410px;
}

.cards2 h3 {
  color: #178b86;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
}

.cards2 p {
  color: #333;
}

.btn {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.modal_button {
  font-size: 12px;
  margin-top: 10px;
}

/*Application*/
.application-section-header {
  color: #005551;
  font-weight: bold;
  letter-spacing: 1px;
  padding-top: 25px;
  font-size: 24px;
}

.application-section {
  background-image: url(./assets/bg-1.png);
}

.application-section-p {
  font-size: 18px;
  color: #005551;
  padding-bottom: 20px;
}

.application-steps h2 {
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
  color: #005e59;
  letter-spacing: 1px;
}

.application-steps h3 {
  color: rgb(23, 140, 134);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.application-steps p {
  color: rgb(66, 66, 66);
  font-size: 14px;
}

.slider-container {
  width: 100%;
  height: 50%;
}

/*Card Screen 2*/
.va_list_container {
  padding: 20px 0;
}
.va_card_container {
  border: 10px solid #fff;
}

.va_card_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}
.va_card_container h4 {
  font-size: 16px;
  padding: 10px;
  color: #fff;
  text-align: center;
}

/*Application 2*/
.steps_main_container {
  margin: 0 130px;
}

.steps_first_row {
  text-align: start;
}

.box {
  border: 3px solid #a7a7a7;
  width: 400px;
  height: 300px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.box_left_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box_left_section h1 {
  padding: 0 4px;
  font-size: 50px;
}
.box_left_section h3 {
  padding: 0 4px;
  font-size: 22px;
}

.box p {
  font-size: 14px;
}

.box1 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.9)),
    url("./assets/apply.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.box2 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.9)),
    url("./assets/resume.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
}

.box3 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.9)),
    url("./assets/interview2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.box4 {
  background-image: linear-gradient(rgba(1, 95, 90, 0.8), rgba(0, 0, 0, 0.9)),
    url("./assets/training.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
  .steps_main_container {
    margin: 0px;
  }
}

@media only screen and (max-width: 711px) {
  .box {
    width: 350px;
    height: 250px;
  }
  .box2,
  .box4 {
    margin-top: 0;
  }
  .box_left_section h1 {
    font-size: 35px;
  }
  .box_left_section h3 {
    font-size: 18px;
  }
  .box p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .box {
    width: auto;
    height: auto;
  }
}

/*FAQ*/

.accordion-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("./assets/wfh2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50vh;
}

.accordion-text {
  height: 50vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.accordion-text h1 {
  color: #fff;
  font-size: 24px;

  font-weight: bold;

  letter-spacing: 1px;
}
.accordion-text p {
  color: #fff;
  font-size: 16px;
  padding: 0 15px 5px 15px;
}

.accordion-button {
  font-size: 16px;
  border: 2px solid rgb(159, 253, 175) !important;
  padding: 10px;
}

.accordion-button:hover {
  font-size: 16px;
  border: 2px solid rgb(76, 252, 76) !important;
}

.accordion-body {
  color: #178b86;
  background: #fff;
  font-size: 14px;
}

.accordion-head {
  color: #178b86;
  background: #fff;
  font-size: 18px;
}

.accordion-body i {
  font-size: 18px;
  color: #005551;
}

.accordion-left {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
}

.accordion-right {
  background-image: url(./assets/accordion-img2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 70vh;
}

/*qualifications-section*/
.qualifications-section {
  margin: 30px;
}

.qualification-card {
  text-align: center;
  background: rgb(18, 106, 102);
}

.qualification-title {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  font-size: 22px;
}

.qualification-text {
  color: #fff;
  font-size: 16px;
}

/*Lower Section*/

.lower-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("./assets/pexels-mateusz-dach-450035.jpg");

  color: #fff;
  padding: 30px;
}

.lower-section h1 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.lower-section h3 {
  font-size: 16px;
}

.lower-section-btn {
  font-size: 16px;
  border: 2px solid rgb(159, 253, 175) !important;
  padding: 10px;
}

.lower-section-btn:hover {
  font-size: 16px;
  border: 2px solid rgb(76, 252, 76) !important;
}

.footer-list {
  background: #fff;
  color: #005551;
  padding: 40px;
}
.footer-list h1 {
  padding-bottom: 10px;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
.footer-list li {
  font-size: 14px;
  list-style: none;
}
.footer-card {
  border: none;
}

.footer-card p {
  color: #005551;
}

.footer-links {
  border-left: 3px solid #005551;
}

/*Footer*/

footer {
  background: #005551;
  color: #fff;
}

/*About page*/
.about-para2 {
  display: none;
}

.about-container {
  background-image: url(./assets/bg-2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 40vh;
}

.about-section {
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-section h1 {
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 1px;
}
.about-section p {
  font-size: 18px;
  padding: 0 230px;
}

.about-section-search {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}

.about-section-search h1 {
  color: #005551;
}

.about-section-search p {
  color: #178b86;

  font-size: 18px;
}

.about-text {
  padding: 50px;

  color: #fff;
  text-align: center;
}

.about-section2 {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.about-section-left {
  border-left: 5px solid #00c573;
  border-top: 5px solid #00c573;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.about-section2 h1 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #005551;
  padding-bottom: 10px;
}

.about-section-left p {
  color: #178b86;
  font-size: 16px;
}

.about-section-right {
  border-right: 5px solid #00c573;
  border-bottom: 5px solid #00c573;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  text-align: center;
  font-size: 16px;
}

.about-section3 {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.about-section-left3 {
  border-top: 5px solid #00c573;
  border-left: 5px solid #00c573;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.about-section3 h1 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #005551;
  padding-bottom: 10px;
}

.about-section-left3 p {
  color: #178b86;
  font-size: 16px;
}

.about-section-right3 {
  border-right: 5px solid #00c573;
  border-bottom: 5px solid #00c573;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  text-align: center;
  font-size: 16px;
}

.mission {
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;
  color: #005551;
}

.mission h1 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.mission p {
  font-size: 20px;
}

.about-cards-container {
  padding-bottom: 20px;
}

.about-cards {
  color: #005551;
}

.about-cards h3 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.about-cards p {
  font-size: 14px;
  color: #5f8b86;
}

.about-button {
  margin: 40px 0;
  padding: 10px;
}
/*Thank you page*/
.thankyou-page {
  color: #005551;
  padding-bottom: 20px;
}
.thankyou-page h1 {
  font-family: "Satisfy", cursive !important;
  letter-spacing: 1px;

  font-size: 160px;
  padding-top: 40px;
}
.thankyou-page h2 {
  font-family: "Satisfy", cursive !important;
  font-size: 50px;
  padding-bottom: 20px;
}
.thankyou-page p {
  font-size: 20px;
  padding: 0px 10px 0px 10px;
}

.thankyou-btn {
  text-transform: capitalize;
  padding: 10px;
  border: 3px solid #00c573 !important;
}

/*Services Page*/
.services-container {
  height: 40vh;
}
.services-text {
  display: flex;
  flex-direction: column;
  height: 40vh;
  align-items: center;
  justify-content: center;
}

.services-container h4 {
  font-size: 30px;

  text-transform: uppercase;
}
.services-container h3 {
  font-size: 20px;
  padding-bottom: 20px;
}

.services-container p {
  font-size: 18px;
}

.services-header {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 24px;
  color: #005551;
}

.services-text {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("./assets/services.jpg");
  background-position: center;
  text-align: center;
  color: #fff;
  padding: 50px;
  height: 40vh;
}

.first-row-container {
  height: 1050px;
}

.second-row-container {
  height: 900px;
}

.third-row-container {
  height: 950px;
}

.services-cards {
  color: #005551;
}
.services-cards h3 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
}

.services-cards h4 {
  font-size: 14px;
  font-weight: bold;
}

.services-cards li {
  font-size: 14px;
}
.services-cards p {
  margin: 0px;
}

.list-p {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

/*Administrative*/
.admin_mobile {
  display: none;
}

@media only screen and (max-width: 753px) {
  .admin_desktop {
    display: none;
  }
  .admin_mobile {
    display: block;
  }
  .services_main_container h2 {
    padding-top: 10px;
  }
  .services_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.admin_img {
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(21, 129, 124, 0.12) 0px -12px 30px,
    rgba(21, 129, 124, 0.12) 0px 4px 6px, rgba(21, 129, 124, 0.17) 0px 12px 13px,
    rgba(21, 129, 124, 0.09) 0px -3px 5px; */
  border: 3px solid #15817c;
}

.services_main_container {
  padding: 20px 0;
}

.admin_row_container {
  padding: 20px 0;
}

.services_main_container h1 {
  text-align: center;
  padding-bottom: 10px;
  color: #15817c;
  text-transform: uppercase;
  font-size: 24px;
}

.services_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.services_text button {
  width: 200px;
}

.services_text h2 {
  color: #15817c;
  text-transform: uppercase;
  font-size: 20px;
}

@media only screen and (max-width: 500px) {
  .admin_row_container {
    padding: 0;
  }
  .services_main_container h1 {
    font-size: 18px;
  }
  .services_text {
    padding: 20px;
  }
  .services_text h2 {
    font-size: 16px;
  }
  .services_text li {
    font-size: 12px !important;
  }
}

.services_main_container li {
  color: #333;
  font-size: 14px;
}

/*Contact Page*/

.contact-page {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url(./assets/contactus.jpg);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-page h1 {
  text-align: center;
  text-transform: uppercase;
  color: #005551;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 24px;
}

.contact-page p {
  text-align: center;
  font-style: italic;
  color: #15817c;
  margin-bottom: 0;
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

.form-button {
  color: #fff;
  letter-spacing: 0;
  padding: 10px;
}

.form-header {
  background: radial-gradient(
    circle,
    rgba(238, 238, 238, 1) 0%,
    rgba(56, 129, 124, 0.3) 100%
  );
  text-align: center;
  font-size: 18px;
  padding-bottom: 20px;
  color: #005551;
  padding-top: 20px;
}

/*Payment Pages*/
.payment-page {
}

.payment-policy-card .card-header {
  font-size: 40px;
  text-align: center;
  background: #005551;
}

.card-payment-header {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 30px;
}

.payment-card-para {
  text-align: center;
  font-size: 22px !important;
  color: #005551;
  padding: 20px 0;
  font-style: italic;
}

.payment-policy-card h3 {
  text-align: center;
  color: #005551;
  padding: 10px 0 10px 0;
  font-weight: bold;

  text-transform: uppercase;
}
.payment-policy-card h4 {
  text-align: center;
  color: #005551;
  padding: 10px 0 10px 0;
}

.payment-policy-card li {
  padding: 10px 0px 10px 10px;
}

.payment-policy-card .card-text {
  padding: 10px 10px 0 10px;
}

.payment-page h2 {
  font-size: 30px;
  color: #005551;
  padding-top: 20px;
}

.payment-button {
  font-size: 16px;
  padding: 10px;
}

.payment-paypal {
  text-align: center;
  padding-top: 40px !important;
}

.payment-paypal i {
  font-size: 60px;
  color: #03a4eb;
}

.stripe-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-page-header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
    url("./assets/payment.jpg");
  background-position: center;
  text-align: center;
  color: #fff;
  padding: 50px;
  height: 40vh;
  color: #fff;
  font-size: 35px;
  padding-top: 90px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #00c573;
}

.payment-row h1 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #005551;
}

.payment-row h2 {
  font-size: 20px;
  color: #333;
}

.payment-button {
  background: #005551;
  color: #fff;
}
.powered_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.powered_container p {
  margin: 0;
}

/*Footer Nav*/
.footer-nav {
  font-size: 14px;
}
.nav-link {
  padding: 1px;
}

.social-media {
  text-align: center;
}

.social-media ul {
  display: flex;

  justify-content: center;
}

.social-media li {
  padding: 0 20px 0 20px;
}

.social-media i {
  font-size: 40px;
}

/*Privacy Policy*/
.privacy h1 {
  color: #005551;
  padding: 10px 0 20px 0;
}
.privacy p {
  color: #333;
}

.privacy a {
  color: #2804ca;
}

/*404*/
.page_not_found_container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.page_not_found_container h1 {
  font-size: 80px;
  color: #005551;
}

.page_not_found_container h2 {
  padding-bottom: 50px;
  font-size: 40px;
}

.page_not_found_container h3 {
  font-size: 20px;
  color: #005551;
}
.pgf-button {
  margin: 20px 0;
  padding: 10px;
  text-transform: capitalize;
}

/*Reviews*/
.about-section {
}

.review_section {
  background-image: url(./assets/testimonial2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 40vh;
  border-bottom: 1px solid #333;
}

.create_review {
  text-align: center;
  padding: 30px 0;
}

.create_review h1 {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #005551;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.review-button {
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 25px;
  letter-spacing: 0.5px;
  font-weight: bold;
  border: 3px solid #005551;
}

.review-button:hover {
  border: 3px solid #009891;
}

.card_container {
  background: rgb(241, 241, 241);
  margin: 20px 0;
  padding: 35px 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card_image_container img {
  padding: 10px;
  background: #005551;
}

.card_section_text_content h3 {
  font-size: 18px;
  padding-top: 10px;
  color: #005551;
}

.card_section_text_content h4 {
  font-size: 16px;
  color: #005551;
}

.card_section_text_content p {
  font-style: italic;
  font-size: 15px;
  padding-top: 10px;
  color: #178b86;
}

.rating span {
  font-size: 25px;
}

/* .tabs_container button {
  color: var(--gray) !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
} */

.nav-tabs {
  font-size: 25px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
}

.reviewform_container {
  text-align: center;
  margin: 20px;
}
.reviewform_container h1 {
  color: #005551;
  text-transform: uppercase;
  font-size: 30px;
}
.reviewform_container p {
  font-size: 16px;
  color: #005551;
}
.reviewform_header {
  font-size: 25px;
  color: #005551;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

/*Review End*/

/*Media Query*/
@media only screen and (max-width: 767px) {
  .cards2 {
    height: 300px;
  }
}

@media only screen and (max-width: 700px) {
  .application-section-header {
    font-size: 18px;
  }

  .row {
    margin: 0;
  }
  /*Navlinks*/
  .nav-links {
    font-size: 14px;
    padding: 0px;
    padding-bottom: 5px;
  }

  /*Hero Section*/
  .hero-body {
    height: 40vh;
  }

  .hero-text {
    height: 40vh;
    padding-right: 0px;
    float: none;
  }

  .hero-text h1 {
    font-size: 40px;
  }

  .hero-text h4 {
    font-size: 20px;
  }

  .hero-text-header-p {
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    margin: 0 20px;
  }

  .hero-text-p {
    font-size: 10px;
    margin: 0px;
    padding-bottom: 2px;
  }

  .hero-button {
    font-size: 10px;
  }

  .button-container {
    padding-top: 30px;
  }

  .button-container p {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

  /*Cards*/

  .card-title {
    margin: 0px;
  }

  .card-section h1 {
    font-size: 18px;
    margin-top: 20px;
    border-top: 1px solid #005551;
    padding-top: 10px;
  }
  .card-section h4 {
    font-size: 12px;
    padding: 0px;
  }

  .card-section p {
    font-size: 15px;
  }
  .cards2 h3 {
    font-size: 13px;
  }

  .card-button {
    font-size: 10px;
    padding: 10px 0;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .cards2 p {
    font-size: 12px;
  }

  /*application steps*/
  .application-section {
    margin-left: 50px;
  }

  .application-section-p {
    font-size: 12px;
    padding: 0 20px;

    margin-bottom: 0;
  }

  /*About section*/

  .about-container {
    height: 30vh;
  }
  .about-section {
    height: 30vh !important;
  }

  /*Services*/

  .modal_button {
    font-size: 10px;
    margin-bottom: 10px;
  }

  /*Modal*/

  .modal-footer {
    border-top: none;
  }

  /*FAQ*/

  .FAQ h1 {
    font-size: 25px;
  }

  .card-header-section-text h3 {
    font-size: 15px;
    margin-top: 15px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .card-header-section-text p {
    color: #005551;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-left: 20px;
    padding-left: 15px;
  }

  .card-header-icons {
    padding: 0px;
    margin-left: 20%;
  }

  .card-icons {
    font-size: 20px;
    margin-left: 0px;
    margin-right: 0;
  }
  .card-header-icons-h4 {
    padding-left: 0 !important;
  }

  .card-header-section-text h4 {
    font-size: 12px;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-header-section-text h5 {
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
  }

  .card-header-section-image {
    padding-left: 80px;
    padding-right: 80px;
  }

  .card-body {
    padding-bottom: 0;
  }

  .accordion-container {
    height: 50vh;
  }

  .accordion-text {
    height: 50vh;
  }

  .accordion-text h1 {
    font-size: 20px;
  }

  .accordion-text p {
    font-size: 14px;
  }

  .accordion-button {
    font-size: 15px;

    margin-top: 5px;
    padding: 12px;
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    border: 3px solid #fff;
  }

  .accordion-head {
    font-size: 15px;
  }
  .accordion-body {
    font-size: 12px;
  }

  .accordion-right {
    background-image: url(./assets/group.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 30vh;
  }

  /*Application*/

  .application-steps h3 {
    font-size: 14px;
  }

  .application-steps p {
    font-size: 12px;
  }

  /*Footer Lower section*/
  .lower-section h1 {
    font-size: 23px;
  }
  .lower-section h3 {
    font-size: 18px;
  }
  .lower-section-btn {
    font-size: 15px;
    width: 300px;
  }

  /*About PAGE*/

  .about-section h1 {
    font-size: 18px;
  }
  .about-icon {
    font-size: 50px;
  }

  .about-section p {
    font-size: 13px;
    padding: 0px 30px;
  }

  .about-section2 {
    padding-top: 20px;
  }

  .about-section2 h1 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .about-section3 h1 {
    font-size: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .about-section3 {
    padding-top: 20px;
  }

  .about-section-left p {
    font-size: 13px;
  }

  .about-section-left3 p {
    font-size: 13px;
  }

  .about-section-search {
    padding: 0;
  }
  .about-section-search h1 {
    font-size: 20px;
    padding: 20px 20px 0px 20px;
  }
  .about-section-search p {
    font-size: 13px;
    padding: 0 30px;
  }

  .about-cards h3 {
    font-size: 18px;
  }
  .about-cards p {
    font-size: 13px;
  }

  .mission h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0 10px;
  }

  /*Thank you page*/

  .thankyou-page h1 {
    font-size: 50px;
    padding-top: 10px;
  }
  .thankyou-page h2 {
    font-size: 25px;
  }
  .thankyou-page p {
    font-size: 15px;
  }

  /*Footer Links*/
  .footer-list h1 {
    font-size: 18px;
  }

  .lower-section h3 {
    font-size: 12px;
  }

  .footer-list li {
    font-size: 10px;
  }

  .footer-card {
    display: none;
  }

  .footer-links {
    border: none;
  }
  .footer-para {
    display: none;
  }

  footer {
    font-size: 12px;
  }

  /*Services Page*/
  .services-text {
    padding: 15px;
  }

  .services-text h4 {
    padding: 0px;

    font-size: 18px;
  }
  .services-text h3 {
    font-size: 13px;
    padding-bottom: 10px;
  }

  .services-text p {
    font-size: 13px;
    padding: 0 15px;
  }

  .services-header {
    font-size: 16px;
    padding: 20px 10px 0px 10px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .services-cards {
    margin: 10px;
  }

  .services-cards h3 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .services-cards p {
    font-size: 11px;
  }

  .services-cards .btn {
    font-size: 14px;
  }

  /*Services Modal*/

  .modal_header {
    background: #333 !important;
  }

  .modal_button {
    margin-top: 0;
  }

  /*Contact Form*/

  .contact-page {
    height: 10vh;
  }

  .contact-page h1 {
    text-align: center;
    text-transform: uppercase;
    color: #005551;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
  }

  .contact-page p {
    text-align: center;
    font-weight: 800;
    color: #15817c;
    font-size: 12px;
  }

  .form-header {
    font-size: 16px;
  }

  /*Contact Form*/

  .logo-mobile {
    display: block;
  }

  .logo-desktop {
    display: none;
  }

  .footer-list {
    padding: 30px 10px 10px 10px;
  }

  .footer-nav {
    margin-bottom: 20px;
  }

  .social-media {
    border-top: 3px solid #00c573;
    padding-top: 10px;
  }
  .social-media i {
    font-size: 20px;
    background: #005551;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
  }

  /*Privacy Policy*/

  html,
  body {
    overflow-x: hidden;
  }
  .privacy {
    padding: 0px 10px 0 10px;
  }

  .privacy h1 {
    font-size: 20px;
  }

  .privacy h2 {
    font-size: 18px;
  }
  .privacy h3 {
    font-size: 16px;
  }

  .privacy li {
    font-size: 14px;
  }

  .privacy p {
    color: #333;
    font-size: 14px;
  }

  .privacy a {
    color: #2804ca;
  }

  /*Payment Pages*/

  .payment-button {
    margin-bottom: 20px;
  }

  .payment-page-header {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25vh;
  }
  .payment-page-header h1 {
    font-size: 16px;
  }
  .payment-page-header h3 {
    font-size: 14px;
  }

  .payment-policy-card h4 {
    font-size: 12px;
  }

  .payment-card-para {
    font-size: 15px;
  }
  .payment-policy-card li {
    font-size: 12px;
  }

  .payment-policy-card h3 {
    font-size: 17px;
  }

  .payment-policy-card p {
    font-size: 12px;
  }
  .payment-card-para {
    font-size: 14px !important;
  }

  /*Review Section*/

  .review_section {
    height: 30vh;
  }

  .create_review h1 {
    font-size: 20px;
  }

  .review-button {
    font-size: 10px;
  }

  .nav-tabs {
    font-size: 14px;
  }

  .card-section img {
    margin: 0px;
    width: 95%;
  }

  .card_section_text_content h3 {
    font-size: 16px;
  }
  .card_section_text_content h4 {
    font-size: 14px;
  }
  .card_section_text_content p {
    font-size: 14px;
  }
  .rating span {
    font-size: 20px;
  }
  .reviewform_container h1 {
    font-size: 20px;
  }
  .reviewform_container p {
    font-size: 12px;
  }
  .reviewform_header {
    font-size: 16px;
  }
}

@media only screen and (max-width: 990px) {
  .first-row,
  .second-row,
  .third-row {
    height: 100%;
  }
  .list-header {
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 900px) {
  .first-row,
  .second-row,
  .third-row {
    height: 100%;
  }
}

@media only screen and (max-width: 746px) {
  .services-cards {
    margin: 20px !important;
  }
  .first-row-container,
  .second-row-container,
  .third-row-container {
    height: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .hero-body {
    height: 45vh;
  }
  .button-container {
    padding-top: 30px;
  }

  .button-container p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .accordion-container {
    height: 50vh;
  }

  .accordion-text {
    height: 50vh;
  }
  .accordion-text h1 {
    font-size: 14px;
  }
  .accordion-text p {
    font-size: 12px;
  }

  .card-section img {
    margin: 0px;
    width: 90%;
    padding: 10px;
    border: 3px solid #055b53;
  }

  .lower-section h1 {
    font-size: 14px;
  }
  .lower-section h3 {
    font-size: 10px;
  }
  .lower-section-btn {
    font-size: 12px;
    padding: 6px;
  }
  .accordion-button {
    font-size: 12px;
    padding: 6px;
  }
}
